/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.for-limited-text {
  background-color: red;
}

.App-link {
  color: #61dafb;
}

.for-limited-section {
  margin-top: -10px;
  margin-bottom: 20px;
}

.custom-padding {
  padding-left: 17.6px;
  padding-right: 18px;
}

.banner-text {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  font-size: 17px;
}

.refer_text {
  margin-top: 10px !important;
}

@media (min-width: 1029px) {
  .give-somthing-main {
    display: block !important;
  }
}

@media (max-width: 1029px) {
  .give-somthing-main {
    display: block !important;
  }
}

@media (max-width: 900px) {
  .give-somthing-main {
    display: block !important;

  }

  .for-limited-section {
    margin-top: 50px;

  }

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}