.footer {
  padding-bottom: 50px;
  background-color: #0b4e00;
}

.footer-text {
  margin-top: 50px;
  color: white;
  font-weight: 600;
  font-size: 20px;
  /* font-family: math; */
}

.footer-text-big {
  margin-top: 50px;
  color: white;
  font-size: 32px;
  font-weight: 700;
  /* font-family: math; */
}

.footer-text-small {
  color: white;
  text-align: left;
  font-size: 18px;
  /* font-family: math; */
}

.footer-text-li {
  display: flex;
  color: white;
  font-size: 15px;
  font-weight: 400;
  list-style-type: none;
  /* font-family: math; */
}

.footer-link {
  color: white;
}

.footer-copyright {
  padding-top: 150px;
}
@media (min-width: 768px) {
  .footer-text-big,
  .footer-text-small {
    padding-left: 100px;
  }
}
