.service-form-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px 80px;
}

.service-form {
  width: 1000px;
  margin-top: 50px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 68px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.service-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

@media (max-width: 576px) {
  .service-form-content {
    padding-left: 6%;
    padding-right: 6%;
  }
}

.service-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;

  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.service-text {
  text-align: center;
  color: #000000;
  font-size: 17px;
  font-weight: 400;
}
.service-text-service {
  text-align: center;
  color: #000000;
  font-weight: 500;
  font-size: 30px;
}
.service-text-me {
  font-weight: 400;
  font-size: 13px;
  padding-left: 10px;
}

.btn-service-back {
  background-color: #000000;
  border-radius: 30px;
  width: 100px;
  height: 40px;
  border-color: #000000;
  text-align: center;
}
.btn-service-next {
  background-color: #1cc300;
  border-radius: 30px;
  width: 100px;
  height: 40px;
  border-color: #1cc300;
  text-align: center;

  float: right;
}

.service-text-label {
  font-weight: bold;
  color: #000000;
  font-size: 15px;
}

.service-circle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #1cc300;
}

.service-center {
  justify-content: center;
}

.service-btn-rate-inactive {
  background-color: white;
  color: #929292;
  width: 60px;
  height: 35px;
  border-radius: 20px;
  border-color: #929292;
}
.service-btn-rate-active {
  background-color: white !important;
  color: #1cc300 !important;
  width: 60px;
  height: 35px;
  border-radius: 20px;
  border-color: #1cc300 !important;
}

@media (max-width: 768px) {
  .service-btn-rate-inactive {
    /* width: 30px;
      height: 17.5px; */
  }
}

.service-photo {
  height: 150px;
  width: 150px;
  background-color: #ebebeb;
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: relative;
}

.service-avatar {
  display: flex;
  justify-content: center;
}
.btn-upload {
  position: absolute;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  background-color: #0b4e00;
}
